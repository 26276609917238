import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';

import { Link } from 'found';
import { Affix, Layout, Menu } from 'antd';
import { get } from 'lodash';
import 'antd/dist/antd.less';

import { setRoles } from '~/helper';
import { Logout } from './auth';
import WebsiteBtn from './website/WebsiteBtn';
import '../../public/css/base.css';

const { Content, Sider } = Layout;

const ROLES = {
  STATE_MANAGER: 'State Manager',
  AREA_MANAGER: 'Area Manager',
};

class App extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string,
      username: PropTypes.string,
      roles: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      })
    }),
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    router: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }

  static defaultProps = {
    children: null,
    viewer: null,
  }

  constructor(props) {
    super(props);

    if (!props.viewer) {
      if (props.match.location.pathname !== '/') {
        props.router.push('/');
        window.location.reload();
      } else {
        props.router.push('/');
      }
    }

    const roles = get(props, 'viewer.roles.edges', []).map(({ node }) => node);
    setRoles(roles);
  }

  hasRole = (roles, role) => roles.some((r) => r === role);

  renderMenu = (viewer, roles) => {
    const username = get(viewer, 'username');
    const style = { display: 'inline-block' };

    let items = [];

    if (username === 'hr') {
      items = [
        { key: "/sales/order", label: <Link to="/sales/order" style={style}>Orders</Link> },
        {
          key: "store", label: "Store", children: [
            { key: "/store", label: <Link to="/store" style={style}>Stores</Link> },
          ]
        },
        {
          key: "report", label: "Report", children: [
            { key: "/dispatch-report", label: <Link to="/report/dispatch-report" style={style}>Dispatch Report</Link> },
            { key: "/sales-target", label: <Link to="/report/sales-target" style={style}>Sales Target</Link> },
          ],
        },
        { key: "logout", label: <Logout viewer={viewer} /> },
      ];
    } else if (roles.length === 0) {
      items = [
        { key: "logout", label: <Logout viewer={viewer} /> },
      ];
    } else if (roles[0] === 'Sales') {
      items = [
        { key: "/sales/order", label: <Link to="/sales/order" style={style}>Orders</Link> },
        { key: "/store", label: <Link to="/store" style={style}>Stores</Link> },
        {
          key: "report", label: "Report", children: [
            { key: "/sales-report", label: <Link to="/report/sales-report" style={style}>Sales Report</Link> },
          ]
        },
        { key: "logout", label: <Logout viewer={viewer} /> },
      ];
    } else if (roles[0] === 'Data Entry' && roles.length === 1) {
      items = [
        { key: "/product", label: <Link to="/product" style={style}>Products</Link> },
        { key: "logout", label: <Logout viewer={viewer} /> },
      ];
    } else if (roles[0] === 'IT' && roles.length === 1) {
      items = [
        {
          key: "store", label: "Store", children: [
            { key: "/store", label: <Link to="/store" style={style}>Stores</Link> },
            { key: "/store/state-manager", label: <Link to="/store/state-manager" style={style}>State Managers</Link> },
          ]
        },
        { key: "/security", label: <Link to="/security" style={style}>Security</Link> },
        { key: "logout", label: <Logout viewer={viewer} /> },
      ];
    } else if (this.hasRole(roles, ROLES.STATE_MANAGER) && roles.every((r) => r === ROLES.STATE_MANAGER || r === ROLES.AREA_MANAGER)) {
      items = [
        {
          key: "store", label: "Store", children: [
            { key: "/store", label: <Link to="/store" style={style}>Stores</Link> },
            { key: "/store/state-manager", label: <Link to="/store/state-manager" style={style}>State Managers</Link> },
          ]
        },
        {
          key: "report", label: "Report", children: [
            { key: "/by-subcategory", label: <Link to="/report/by-subcategory" style={style}>Performance</Link> },
            { key: "/dispatch-report", label: <Link to="/report/dispatch-report" style={style}>Dispatch Report</Link> },
          ]
        },
        { key: "logout", label: <Logout viewer={viewer} /> },
      ]
    } else if (roles[0] === ROLES.AREA_MANAGER && roles.length === 1) {
      items = [
        {
          key: "report", label: "Report", children: [
            { key: "/by-subcategory", label: <Link to="/report/by-subcategory" style={style}>Performance</Link> },
            { key: "/dispatch-report", label: <Link to="/report/dispatch-report" style={style}>Dispatch Report</Link> },
          ]
        },
        { key: "logout", label: <Logout viewer={viewer} /> },
      ]
    } else {
      items = [
        { key: "/product", label: <Link to="/product" style={style}>Products</Link> },
        {
          key: "promotion", label: "Promotions", children: [
            { key: "/bonus", label: <Link to="/promotion/bonus" style={style}>Bonuses</Link> },
            { key: "/redemption", label: <Link to="/promotion/redemption" style={style}>Redemptions</Link> },
            { key: "/label", label: <Link to="/promotion/label" style={style}>Labels</Link> },
            { key: "/credit", label: <Link to="/promotion/credit" style={style}>Credits</Link> },
            { key: "/common-promo", label: <Link to="/promotion/common-promo" style={style}>Common Promo</Link> },
            { key: "/freebie", label: <Link to="/promotion/freebie" style={style}>Freebies</Link> },
            { key: "/combo-freebie", label: <Link to="/promotion/combo-freebie" style={style}>Combo Freebies</Link> },
            { key: "/adjacent-banner", label: <Link to="/promotion/adjacent-banner" style={style}>Adjacent Banners</Link> },
            { key: "/coupon", label: <Link to="/promotion/coupon" style={style}>Coupons</Link> },
            { key: "/alert", label: <Link to="/promotion/alert" style={style}>Alerts</Link> },
            { key: "/purchase-limit", label: <Link to="/promotion/purchase-limit" style={style}>Purchase Limit</Link> },
            { key: "/card", label: <Link to="/promotion/card" style={style}>Cards</Link> },
          ]
        },
        { key: "/sales/order", label: <Link to="/sales/order" style={style}>Orders</Link> },
        {
          key: "gift-card", label: "Gift Cards", children: [
            { key: "/setup", label: <Link to="/gift-card/setup" style={style}>Setup</Link> },
            { key: "/usage", label: <Link to="/gift-card/usage" style={style}>Usages</Link> },
          ]
        },
        { key: "/customer", label: <Link to="/customer" style={style}>Customers</Link> },
        { key: "/sales/quote", label: <Link to="/sales/quote" style={style}>Quotes</Link> },
        { key: "/subcategory", label: <Link to="/subcategory" style={style}>Sub Categories</Link> },
        { key: "/category", label: <Link to="/category" style={style}>Categories</Link> },
        { key: "/brand", label: <Link to="/brand" style={style}>Brands</Link> },
        { key: "/attribute", label: <Link to="/attribute" style={style}>Attributes</Link> },
        {
          key: "cms", label: "CMS", children: [
            { key: "/carousel", label: <Link to="/cms/carousel" style={style}>Carousel</Link> },
            { key: "/banner", label: <Link to="/cms/banner" style={style}>Banner</Link> },
            { key: "/page", label: <Link to="/cms/page" style={style}>CMS Pages</Link> },
            { key: "/faq", label: <Link to="/cms/faq" style={style}>FAQ</Link> },
            { key: "/footer", label: <Link to="/cms/footer" style={style}>Footer</Link> },
          ]
        },
        { key: "/review", label: <Link to="/review" style={style}>Reviews</Link> },
        { key: "/price-match", label: <Link to="/price-match" style={style}>Price Matches</Link> },
        {
          key: "store", label: "Store", children: [
            { key: "/store", label: <Link to="/store" style={style}>Stores</Link> },
            { key: "/store/state-manager", label: <Link to="/store/state-manager" style={style}>State Managers</Link> },
          ]
        },
        { key: "/user", label: <Link to="/user" style={style}>User Management</Link> },
        {
          key: "search", label: "Search", children: [
            { key: "/search-terms-report", label: <Link to="/search/search-terms-report" style={style}>Search Terms Report</Link> },
            { key: "/synonym", label: <Link to="/search/synonym" style={style}>Synonyms</Link> },
            { key: "/redirect", label: <Link to="/search/redirect" style={style}>Redirects</Link> },
          ]
        },
        { key: "/url/rewrite", label: <Link to="/url/rewrite" style={style}>Url Redirects</Link> },
        {
          key: "report", label: "Report", children: [
            { key: "/by-subcategory", label: <Link to="/report/by-subcategory" style={style}>Performance</Link> },
            { key: "/revenue-report", label: <Link to="/report/revenue-report" style={style}>Revenue Report</Link> },
            { key: "/sales-report", label: <Link to="/report/sales-report" style={style}>Sales Report</Link> },
            { key: "/sales-trend", label: <Link to="/report/sales-trend" style={style}>Sales Trend</Link> },
            { key: "/by-min-max", label: <Link to="/report/by-min-max" style={style}>By Min-Max</Link> },
            { key: "/dispatch-report", label: <Link to="/report/dispatch-report" style={style}>Dispatch Report</Link> },
            { key: "/sales-target", label: <Link to="/report/sales-target" style={style}>Sales Target</Link> },
          ]
        },
        { key: "/security", label: <Link to="/security" style={style}>Security</Link> },
        { key: "/config", label: <Link to="/config" style={style}>Configurations</Link> },
        { key: "logout", label: <Logout viewer={viewer} /> },
      ];
    }

    return (
      <Menu theme="dark" mode="inline" items={items} />
    )
  }

  render() {
    const { viewer, children } = this.props;
    let roles = [];

    if (viewer) {
      roles = viewer.roles.edges.map(({ node }) => node.name);
    }

    return (
      <div>
        <Layout>
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
          >
            <div className="logo" />
            {this.renderMenu(viewer, roles)}
            <Affix>
              <WebsiteBtn style={{marginLeft: '24px'}} />
            </Affix>
          </Sider>
          <Layout>
            <Content
              style={{
                margin: '24px 16px',
                padding: 24,
                background: '#fff',
                minHeight: 280,
              }}
            >
              {children}
            </Content>
          </Layout>
        </Layout>
      </div>
    );
  }
}
export default createFragmentContainer(App, {
  viewer: graphql`
    fragment App_viewer on Admin {
      id
      username
      roles(first: 99){
        edges {
          node {
            name
            defaultValues
          }
        }
      }
      ...Logout_viewer
    }
  `,
});
